import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './contact1.css'

const Contact1 = (props) => {
  return (
    <div className="contact1-contact20 thq-section-padding">
      <div className="contact1-max-width thq-section-max-width">
        <a
          href="mailto:airmaxx-ks@outlook.de?subject="
          className="contact1-link1"
        >
          <div className="contact1-content1">
            <svg viewBox="0 0 1024 1024" className="thq-icon-medium">
              <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
            </svg>
            <div className="contact1-contact-info1">
              <div className="contact1-content2">
                <h3 className="contact1-text10 thq-heading-3">Email</h3>
              </div>
              <span>
                {props.email1 ?? (
                  <Fragment>
                    <span className="contact1-text15 thq-body-small">
                      <span>airmaxx-ks@outlook.de</span>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </a>
        <a
          href="https://www.instagram.com/airmaxx.ks"
          target="_blank"
          rel="noreferrer noopener"
          className="contact1-link2"
        >
          <div className="contact1-content3">
            <svg viewBox="0 0 1024 1024" className="contact1-icon3">
              <path d="M298.667 42.667c-70.699 0-134.741 28.715-181.035 74.965s-74.965 110.336-74.965 181.035v426.667c0 70.699 28.715 134.741 74.965 181.035s110.336 74.965 181.035 74.965h426.667c70.699 0 134.741-28.715 181.035-74.965s74.965-110.336 74.965-181.035v-426.667c0-70.699-28.715-134.741-74.965-181.035s-110.336-74.965-181.035-74.965zM298.667 128h426.667c47.147 0 89.728 19.072 120.661 50.005s50.005 73.515 50.005 120.661v426.667c0 47.147-19.072 89.728-50.005 120.661s-73.515 50.005-120.661 50.005h-426.667c-47.147 0-89.728-19.072-120.661-50.005s-50.005-73.515-50.005-120.661v-426.667c0-47.147 19.072-89.728 50.005-120.661s73.515-50.005 120.661-50.005zM724.864 478.848c-6.4-41.472-24.363-79.232-50.944-109.525-32.171-36.736-76.971-62.507-128.384-70.144-19.797-3.157-41.387-3.285-63.019-0.085-58.283 8.619-107.563 40.149-140.032 83.925s-48.341 100.139-39.68 158.379 40.149 107.563 83.925 140.032 100.139 48.341 158.379 39.68 107.563-40.149 140.032-83.925 48.341-100.139 39.68-158.379zM640.469 491.392c5.205 34.987-4.267 68.651-23.808 95.019s-49.067 45.184-84.011 50.347-68.651-4.267-95.019-23.808-45.184-49.067-50.347-84.011 4.267-68.651 23.808-95.019 49.067-45.184 84.011-50.347c13.355-1.963 26.24-1.792 37.12-0.085 31.573 4.693 58.283 20.053 77.568 42.069 16 18.261 26.88 41.088 30.72 65.835zM789.333 277.333c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667 19.115 42.667 42.667 42.667 42.667-19.115 42.667-42.667z"></path>
            </svg>
            <div className="contact1-contact-info2">
              <div className="contact1-content4">
                <h3 className="contact1-text11 thq-heading-3">Instagram</h3>
              </div>
              <span>
                {props.email11 ?? (
                  <Fragment>
                    <span className="contact1-text19 thq-body-small">
                      <span>@airmaxx.ks</span>
                      <br></br>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </a>
        <a
          href="https://maps.app.goo.gl/TP4fxxY6GWTgckk77"
          target="_blank"
          rel="noreferrer noopener"
          className="contact1-link3"
        >
          <div className="contact1-content5">
            <svg viewBox="0 0 1024 1024" className="thq-icon-medium">
              <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
            </svg>
            <div className="contact1-contact-info3">
              <div className="contact1-content6">
                <h3 className="contact1-text12 thq-heading-3">
                  <span>Standort</span>
                  <br></br>
                </h3>
              </div>
              <span>
                {props.address1 ?? (
                  <Fragment>
                    <span className="contact1-text18 thq-body-small">
                      34130 Kassel, Hessen
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

Contact1.defaultProps = {
  email1: undefined,
  address1: undefined,
  email11: undefined,
}

Contact1.propTypes = {
  email1: PropTypes.element,
  address1: PropTypes.element,
  email11: PropTypes.element,
}

export default Contact1
