import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './hero17.css'

const Hero17 = (props) => {
  return (
    <div className={`hero17-header78 ${props.rootClassName} `}>
      <div
        id="welcomeContainer"
        className="hero17-column thq-section-max-width thq-section-padding"
      >
        <div className="hero17-content1">
          <h1>
            {props.heading1 ?? (
              <Fragment>
                <h1 id="welcome" className="hero17-text6 thq-heading-1">
                  Willkommen bei Airmaxx Drohnenfotografie
                </h1>
              </Fragment>
            )}
          </h1>
          <p>
            {props.content1 ?? (
              <Fragment>
                <p className="hero17-text7 thq-body-large">
                  Erlebe die Welt aus einer neuen Perspektive mit meinen
                  atemberaubenden FPV-Drohnenaufnahmen. Ich biete
                  professionelle Luftaufnahmen für Events, Hochzeiten, Immobilien und mehr.
                </p>
              </Fragment>
            )}
          </p>
        </div>
        <div className="hero17-actions">
          <a
            href="#features"
            name="Dienstleistungen"
            className="hero17-button1 thq-button-filled"
          >
            <span>
              {props.action1 ?? (
                <Fragment>
                  <span className="hero17-text5 thq-body-small">
                    Meine Dienstleistungen entdecken
                  </span>
                </Fragment>
              )}
            </span>
          </a>
          <a href="#contactform" className="hero17-button2 thq-button-outline">
            <span>
              {props.action2 ?? (
                <Fragment>
                  <span className="hero17-text8 thq-body-small">
                    Kontaktiere mich für ein Angebot
                  </span>
                </Fragment>
              )}
            </span>
          </a>
        </div>
      </div>
      <div className="hero17-content2">
        <div className="hero17-row-container1 thq-mask-image-horizontal thq-animated-group-container-horizontal">
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="hero17-placeholder-image10 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="hero17-placeholder-image11 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="hero17-placeholder-image12 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image4Alt}
              src="https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDF8fExhbmRzY2FwZXxlbnwwfHx8fDE3MjQxNTQ5NzB8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero17-placeholder-image13 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero17-placeholder-image14 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image6Alt}
              src={props.image6Src}
              className="hero17-placeholder-image15 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="hero17-placeholder-image16 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="hero17-placeholder-image17 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="hero17-placeholder-image18 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="hero17-placeholder-image19 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero17-placeholder-image20 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1534312527009-56c7016453e6?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero17-placeholder-image21 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
        </div>
        <div className="hero17-row-container2 thq-mask-image-horizontal thq-animated-group-container-horizontal">
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="hero17-placeholder-image22 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="hero17-placeholder-image23 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="hero17-placeholder-image24 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="hero17-placeholder-image25 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="hero17-placeholder-image26 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image12Alt}
              src={props.image12Src}
              className="hero17-placeholder-image27 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="hero17-placeholder-image28 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="hero17-placeholder-image29 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="hero17-placeholder-image30 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="hero17-placeholder-image31 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="hero17-placeholder-image32 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1568214379698-8aeb8c6c6ac8?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxncmFmaWN8ZW58MHx8fHwxNzE1Nzk0OTk5fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero17-placeholder-image33 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="hero17-container2">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

Hero17.defaultProps = {
  image3Src:
    'https://images.unsplash.com/photo-1508233620467-f79f1e317a05?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDExfHxEcm9uZXxlbnwwfHx8fDE3MjQxNTQxNjZ8MA&ixlib=rb-4.0.3&w=1500',
  image6Src:
    'https://images.unsplash.com/photo-1504217051514-96afa06398be?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE3fHxMYW5kc2NhcGV8ZW58MHx8fHwxNzI0MTU0OTcwfDA&ixlib=rb-4.0.3&w=1500',
  image8Src:
    'https://images.unsplash.com/photo-1606633473849-bd477e343d97?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNDE1MDQ5Mnw&ixlib=rb-4.0.3&q=80&w=1080',
  image12Alt: 'Aerial Shot of Corporate Event',
  action1: undefined,
  heading1: undefined,
  image9Src:
    'https://images.unsplash.com/photo-1504890001746-a9a68eda46e2?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDl8fERyb25lfGVufDB8fHx8MTcyNDE1NDE2Nnww&ixlib=rb-4.0.3&w=1500',
  image11Alt: 'FPV Drone Racing Competition',
  content1: undefined,
  image8Alt: 'Drone Photography at Sunset',
  image2Src:
    'https://images.unsplash.com/photo-1470894402574-b766d274deb7?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIwfHxEcm9uZXxlbnwwfHx8fDE3MjQxNTQxNjZ8MA&ixlib=rb-4.0.3&w=1500',
  action2: undefined,
  image4Alt: 'FPV Drone Racing through Forest',
  image10Alt: 'Drone Capturing Sports Event',
  image7Alt: 'FPV Drone Flying over Mountains',
  image1Alt: 'FPV Drone Flying over City',
  image4Src:
    'https://images.unsplash.com/photo-1473186639016-1451879a06f0?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEzfHxEcm9uZXxlbnwwfHx8fDE3MjQxNTQxNjZ8MA&ixlib=rb-4.0.3&w=1500',
  image5Alt: 'Drone Filming Outdoor Event',
  rootClassName: '',
  image7Src:
    'https://images.unsplash.com/photo-1456615913800-c33540eac399?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEwfHxEcm9uZXxlbnwwfHx8fDE3MjQxNTQxNjZ8MA&ixlib=rb-4.0.3&w=1500',
  image6Alt: 'Aerial Shot of Urban Area',
  image3Alt: 'Aerial View of Landscape',
  image11Src:
    'https://images.unsplash.com/photo-1665649925251-5d9a6d217a83?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNDE1MDQ5MXw&ixlib=rb-4.0.3&q=80&w=1080',
  image9Alt: 'Aerial View of Beach Wedding',
  image10Src:
    'https://images.unsplash.com/photo-1573388311283-521e63f9a334?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEzfHxGUFZ8ZW58MHx8fHwxNzI0MTU1MDYwfDA&ixlib=rb-4.0.3&w=1500',
  image2Alt: 'Drone Capturing Wedding Ceremony',
  image1Src:
    'https://images.unsplash.com/photo-1515256722043-0f2b082ddadc?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE3fHxEcm9uZXxlbnwwfHx8fDE3MjQxNTQxNjZ8MA&ixlib=rb-4.0.3&w=1500',
  image5Src:
    'https://images.unsplash.com/photo-1724050021636-fc2bcfb61cf9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNDE1MDQ5Mnw&ixlib=rb-4.0.3&q=80&w=1080',
  image12Src:
    'https://images.unsplash.com/photo-1509709019388-1bab5c4a54df?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDF8fEJpcnRoZGF5JTIwZHJvbmUlMjB8ZW58MHx8fHwxNzI0MTU1MTAwfDA&ixlib=rb-4.0.3&w=1500',
}

Hero17.propTypes = {
  image3Src: PropTypes.string,
  image6Src: PropTypes.string,
  image8Src: PropTypes.string,
  image12Alt: PropTypes.string,
  action1: PropTypes.element,
  heading1: PropTypes.element,
  image9Src: PropTypes.string,
  image11Alt: PropTypes.string,
  content1: PropTypes.element,
  image8Alt: PropTypes.string,
  image2Src: PropTypes.string,
  action2: PropTypes.element,
  image4Alt: PropTypes.string,
  image10Alt: PropTypes.string,
  image7Alt: PropTypes.string,
  image1Alt: PropTypes.string,
  image4Src: PropTypes.string,
  image5Alt: PropTypes.string,
  rootClassName: PropTypes.string,
  image7Src: PropTypes.string,
  image6Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  image11Src: PropTypes.string,
  image9Alt: PropTypes.string,
  image10Src: PropTypes.string,
  image2Alt: PropTypes.string,
  image1Src: PropTypes.string,
  image5Src: PropTypes.string,
  image12Src: PropTypes.string,
}

export default Hero17
