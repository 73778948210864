import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import './style.css';
import Home from './views/home';
import NotFound from './views/not-found';

const App = () => {
  return (
    <Router basename="/airmaxx">
      <Switch>
        <Route exact path="/" component={Home} />
        {/* Die NotFound-Seite für alle nicht übereinstimmenden Routen */}
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
