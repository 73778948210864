import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Hero17 from '../components/hero17'
import CTA26 from '../components/cta26'
import Features25 from '../components/features25'
import Steps2 from '../components/steps2'
import Testimonial17 from '../components/testimonial17'
import ContactForm4 from '../components/contact-form4'
import Contact1 from '../components/contact1'
import Footer4 from '../components/footer4'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Airmaxx</title>
        <meta property="og:title" content="Airmaxx" />
      </Helmet>
      <Hero17
        action1={
          <Fragment>
            <span className="home-text10 thq-body-small">
              Meine Dienstleistungen entdecken
            </span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text11 thq-body-small">
              Kontaktiere mich für ein Angebot
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text12 thq-body-large">
                Erlebe die Welt aus einer neuen Perspektive mit meinen
                atemberaubenden FPV-Drohnenaufnahmen. Ich biete
                professionelle Luftaufnahmen für Events, Hochzeiten, Immobilien und mehr.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text13 thq-heading-1">
              Willkommen bei Airmaxx Drohnenfotografie
            </span>
          </Fragment>
        }
        rootClassName="hero17root-class-name"
      ></Hero17>
      <CTA26
        action1={
          <Fragment>
            <span className="home-text14">Kontaktiere mich jetzt</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text15 thq-body-large">
              Erlebe atemberaubende FPV-Drohnenaufnahmen für besondere Anlässe
              oder Events. Ich stehe bereit, um deine Visionen
              in die Realität umzusetzen.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text16 thq-heading-2">
              Buche noch heute deine einzigartige Luftaufnahme!
            </span>
          </Fragment>
        }
        rootClassName="cta26root-class-name"
      ></CTA26>
      <Features25
        feature1Title={
          <Fragment>
            <span className="home-text17 thq-heading-2">Eventaufnahmen</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text18 thq-heading-2">
              Hochzeitsfotografie
            </span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text19 thq-heading-2">Luftbilder</span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text20 thq-body-small">
              Professionelle FPV-Drohnenaufnahmen für besondere Events und
              Veranstaltungen.
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text21 thq-body-small">
              Unvergessliche Luftaufnahmen und Videos für den schönsten Tag
              deines Lebens.
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text22 thq-body-small">
              Erfasse atemberaubende Luftbilder von Landschaften,
              Immobilien und mehr.
            </span>
          </Fragment>
        }
      ></Features25>
      <Steps2
        step1Title={
          <Fragment>
            <span className="home-text23 thq-heading-2">
              Kontaktiere mich
            </span>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <span className="home-text24 thq-heading-2">
              Planung und Vorbereitung
            </span>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <span className="home-text25 thq-heading-2">Drohnen-Shooting</span>
          </Fragment>
        }
        step4Title={
          <Fragment>
            <span className="home-text26 thq-heading-2">
              Bearbeitung und Lieferung
            </span>
          </Fragment>
        }
        step1Description={
          <Fragment>
            <span className="home-text27 thq-body-small">
              Nehme Kontakt mit mir auf, um deine Anforderungen und Wünsche
              zu besprechen.
            </span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text28 thq-body-small">
              Gemeinsam planen wir den Ablauf des Shootings und bereiten alles
              für den Einsatz der Drohne vor.
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text29 thq-body-small">
              Ich führe das Drohnen-Shooting durch,
              um atemberaubende Luftaufnahmen zu erstellen.
            </span>
          </Fragment>
        }
        step4Description={
          <Fragment>
            <span className="home-text30 thq-body-small">
              Nach dem Shooting bearbeite ich die Aufnahmen
              professionell und liefere sie dir in hoher Qualität.
            </span>
          </Fragment>
        }
      ></Steps2>
      <Testimonial17
        review1={
          <Fragment>
            <span className="home-text31 thq-body-small">
            Lasse heute noch eine Bewertung über das Kontaktformular da!
            </span>
          </Fragment>
        }
        review2={
          <Fragment>
            <span className="home-text32 thq-body-small">
            Lasse heute noch eine Bewertung über das Kontaktformular da!
            </span>
          </Fragment>
        }
        review3={
          <Fragment>
            <span className="home-text33 thq-body-small">
            Lasse heute noch eine Bewertung über das Kontaktformular da!
            </span>
          </Fragment>
        }
        review4={
          <Fragment>
            <span className="home-text34 thq-body-small">
            Lasse heute noch eine Bewertung über das Kontaktformular da!
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text35 thq-body-small">
            Bewertungen meiner früheren Kunden
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text36 thq-heading-2">Kundenbewertungen</span>
          </Fragment>
        }
        author1Name={
          <Fragment>
            <span className="home-text37 thq-body-large">???</span>
          </Fragment>
        }
        author2Name={
          <Fragment>
            <span className="home-text38 thq-body-large">???</span>
          </Fragment>
        }
        author3Name={
          <Fragment>
            <span className="home-text39 thq-body-large">???</span>
          </Fragment>
        }
        author4Name={
          <Fragment>
            <span className="home-text40 thq-body-large">???</span>
          </Fragment>
        }
        author1Position={
          <Fragment>
            <span className="home-text41 thq-body-small">???</span>
          </Fragment>
        }
        author2Position={
          <Fragment>
            <span className="home-text42 thq-body-small">???</span>
          </Fragment>
        }
        author3Position={
          <Fragment>
            <span className="home-text43 thq-body-small">???</span>
          </Fragment>
        }
        author4Position={
          <Fragment>
            <span className="home-text44 thq-body-small">???</span>
          </Fragment>
        }
      ></Testimonial17>
      <ContactForm4
        action={
          <Fragment>
            <span className="home-text45 thq-body-small">
              Kontakt aufnehmen
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text46 thq-body-small">
              <span>
                Hast du Fragen oder möchtest mehr über meine
                Drohnenaufnahmen erfahren? Zögere nicht, mich zu
                kontaktieren!
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="home-text49 thq-body-small">
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text50 thq-heading-2">
              Kontaktiere mich
            </span>
          </Fragment>
        }
      ></ContactForm4>
      <Contact1
        email1={
          <Fragment>
            <span className="home-text51 thq-body-small">
              <span>airmaxx-ks@outlook.de</span>
              <br></br>
            </span>
          </Fragment>
        }
        address1={
          <Fragment>
            <span className="home-text54 thq-body-small">
              34130 Kassel, Hessen
            </span>
          </Fragment>
        }
        email11={
          <Fragment>
            <span className="home-text55 thq-body-small">
              <span>@airmaxx.ks</span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
      ></Contact1>
      <Footer4
        text={
          <Fragment>
            <span className="home-text59">
              <span>www.airmaxx-ks.de</span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        link1={
          <Fragment>
            <span className="home-text63 thq-body-small">Home</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="home-text64 thq-body-small">Contact Us</span>
          </Fragment>
        }
        logoSrc="logonew-200h.png"
        termsLink={
          <Fragment>
            <span className="home-text65 thq-body-small">
              Terms and Conditions
            </span>
          </Fragment>
        }
        privacyLink={
          <Fragment>
            <span className="home-text66 thq-body-small">Privacy Policy</span>
          </Fragment>
        }
      ></Footer4>
    </div>
  )
}

export default Home
