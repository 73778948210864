import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './contact-form4.css';

const ContactForm4 = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    acceptedTerms: false,
  });

  const [formStatus, setFormStatus] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Hier entfällt die manuelle Behandlung des Submit-Events

  return (
    <div className="contact-form4-contact9 thq-section-padding" id="contactform">
      <div className="thq-flex-row thq-section-max-width contact-form4-max-width">
        <div className="contact-form4-content1 thq-flex-column">
          <div className="contact-form4-section-title thq-card">
            <span>
              {props.content2 ?? (
                <span className="contact-form4-text19 thq-body-small">
                </span>
              )}
            </span>
            <div className="contact-form4-content2">
              <h2>
                {props.heading1 ?? (
                  <h2 className="contact-form4-text17 thq-heading-2">
                    Kontaktiere mich
                  </h2>
                )}
              </h2>
              <span>
                {props.content1 ?? (
                  <span className="contact-form4-text20 thq-body-small">
                    Hast du Fragen oder möchtest mehr über meine
                    Drohnenaufnahmen erfahren? Zögere nicht, mich zu
                    kontaktieren!
                  </span>
                )}
              </span>
            </div>
          </div>
          {/* Verwende hier den Formspree-Link */}
          <form
            className="thq-card"
            action="https://formspree.io/f/xrbzadaa"
            method="POST"
            // Entferne das onSubmit-Event hier
          >
            <div className="contact-form4-input1">
              <label htmlFor="contact-form-4-name" className="thq-body-small">
                Name
              </label>
              <input
                type="text"
                id="contact-form-4-name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                className="thq-input"
                required
              />
            </div>
            <div className="contact-form4-input2">
              <label htmlFor="contact-form-4-email" className="thq-body-small">
                Email
              </label>
              <input
                type="email"
                id="contact-form-4-email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="thq-input"
                required
              />
            </div>
            <div className="contact-form4-container">
              <label
                htmlFor="contact-form-4-message"
                className="thq-body-small"
              >
                Nachricht
              </label>
              <textarea
                id="contact-form-4-message"
                name="message"
                rows="3"
                value={formData.message}
                onChange={handleChange}
                placeholder="Schreibe mir eine Nachricht mit deinen Anliegen"
                className="thq-input"
                required
              ></textarea>
            </div>
            <div className="contact-form4-checkbox1">
              <input
                type="checkbox"
                id="contact-form-4-check"
                name="acceptedTerms"
                checked={formData.acceptedTerms}
                onChange={handleChange}
                className="thq-checkbox"
                required
              />
              <label
                htmlFor="contact-form-4-check"
                className="contact-form4-text16 thq-body-small"
              >
                Ich aktzeptiere die Terms and Conditions
              </label>
            </div>
            <button
              type="submit"
              className="contact-form4-button thq-button-filled"
            >
              <span>
                {props.action ?? (
                  <span className="contact-form4-text18 thq-body-small">
                    Kontakt aufnehmen
                  </span>
                )}
              </span>
            </button>
          </form>
          {/* Anzeige des Formularstatus */}
          {formStatus && <p>{formStatus}</p>}
        </div>
      </div>
    </div>
  );
};

ContactForm4.defaultProps = {
  heading1: undefined,
  action: undefined,
  content2: undefined,
  content1: undefined,
};

ContactForm4.propTypes = {
  heading1: PropTypes.element,
  action: PropTypes.element,
  content2: PropTypes.element,
  content1: PropTypes.element,
};

export default ContactForm4;
