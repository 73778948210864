import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './footer4.css'

const Footer4 = (props) => {
  return (
    <footer className="footer4-footer7 thq-section-padding">
      <div className="footer4-max-width thq-section-max-width">
        <div className="footer4-content">
          <div className="footer4-logo1">
            <img
              alt={props.logoAlt}
              src={props.logoSrc}
              className="footer4-logo2"
            />
          </div>
          <div className="footer4-links">
            <a href="#welcomeContainer" className="footer4-link1">
              {props.link1 ?? (
                <Fragment>
                  <span className="footer4-text17 thq-body-small">Home</span>
                </Fragment>
              )}
            </a>
            <a href="#contactform" className="footer4-link2">
              {props.link5 ?? (
                <Fragment>
                  <span className="footer4-text22 thq-body-small">
                    Contact Us
                  </span>
                </Fragment>
              )}
            </a>
          </div>
        </div>
        <div className="footer4-credits">
          <div className="thq-divider-horizontal"></div>
          <div className="footer4-row">
            <div className="footer4-container">
              <span className="thq-body-small">
                <span>© 2024 Airmaxx</span>
                <br></br>
              </span>
            </div>
            <div className="footer4-footer-links">
              <a
                href="https://www.termsfeed.com/live/36c13368-4a55-4df8-979f-900f0a8739d5"
                target="_blank"
                rel="noreferrer noopener"
                className="footer4-text13"
              >
                {props.privacyLink ?? (
                  <Fragment>
                    <span className="footer4-text16 thq-body-small">
                      Privacy Policy
                    </span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://www.termsfeed.com/live/25bac511-e272-48cd-89b2-ab068153a56f"
                target="_blank"
                rel="noreferrer noopener"
                className="footer4-text14"
              >
                {props.termsLink ?? (
                  <Fragment>
                    <span className="footer4-text23 thq-body-small">
                      Terms and Conditions
                    </span>
                  </Fragment>
                )}
              </a>
            </div>
            <span>
              {props.text ?? (
                <Fragment>
                  <span className="footer4-text18">
                    <span>www.airmaxx-ks.de</span>
                    <br></br>
                    <br></br>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer4.defaultProps = {
  privacyLink: undefined,
  logoSrc: `${process.env.PUBLIC_URL}/logonew-200h.png`,
  link1: undefined,
  logoSrc: `${process.env.PUBLIC_URL}/logonew-200h.png`,
  text: undefined,
  link5: undefined,
  termsLink: undefined,
}

Footer4.propTypes = {
  privacyLink: PropTypes.element,
  logoAlt: PropTypes.string,
  link1: PropTypes.element,
  logoSrc: PropTypes.string,
  text: PropTypes.element,
  link5: PropTypes.element,
  termsLink: PropTypes.element,
}

export default Footer4
